@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.type-writer strong {
  color: #5abfe8;
  font-weight: 700;
  white-space: nowrap;
}
.type-writer span {
  color: white;
  font-weight: 700;
  white-space: nowrap;
}
.type-writer-features strong {
  color: #053a47;
}
.type-writer-features span {
  color: #5abfe8;
}
.why-choose-us--slider .slick-list {
  padding: 0 40px 0 40px;
}

.cd-section-container {
  @apply min-h-screen py-10 flex items-center justify-center max-lg:px-5 lg:px-10;
}

.cd-text-title {
  @apply text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold;
}

