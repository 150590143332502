$dark: rgb(29, 29, 29);
$light: #fff;
$primary: rgb(162, 162, 246);
$bg: rgb(244, 244, 255);

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 90px;

@mixin breakpoint($point) {
    @if $point==md {
        // 1080px
        @media (min-width: 1024px) {
            @content;
        }
    }
}

.header {
    background: $light;

    @include breakpoint(md) {
        height: $headerHeight;
    }

    transition: 0.3s ease all;

    &__content {
        overflow: hidden;
        color: $light;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @include breakpoint(md) {
            color: $dark;
        }

        &__logo {
            display: flex;
            justify-content: center;

            img {
                height: 42px;
                width: auto;
            }
        }

        &__nav {
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: left;
            background: rgba($dark, 0.9);
            backdrop-filter: blur(2px);
            transform: translate(0);
            transition: 0.3s ease transform;
            // z-index: 99;

            @include breakpoint(md) {
                transform: none;
                flex-direction: row;
                background: transparent;
                width: auto;
                height: 100%;
                position: static;
            }

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: $spacing-lg;
                // z-index: 100;

                @include breakpoint(md) {
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 0;
                    margin-right: calc(0.5rem + #{$spacing-md});

                    width: 100%;
                    height: 100%;
                }
            }

            button {
                cursor: pointer;
                outline: none;
                padding: 0.75rem 1.25rem;
                border-radius: $borderRadius;
                font-size: 1rem;
                font-family: inherit;
                background: $primary;
                color: $dark;
                border: 1px solid transparent;
                transition: 0.3s ease all;

                &:hover {
                    border-color: $primary;
                    background: rgba($primary, 0.1);
                    color: $primary;
                }

                &:active {
                    border-color: $primary;
                    background: linear-gradient(rgba($primary, 0.2), rgba($primary, 0.3));
                    color: $primary;
                }
            }

            &.isMenu {
                transform: translate(100%);
            }
        }

        &__toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 1.5rem;
            transition: 0.3s ease all;
            position: relative;

            @include breakpoint(md) {
                display: none;
            }
        }
    }
}

.menuItemMobile {
    li {
        padding: 1.25rem;
        background-color: #F8F9FA;
        margin: 0.5rem 0;
        text-align: center;
        list-style: none;
    }
}
